import React from "react";


const detailsClickHandler = (idString) => {
  console.log(idString);
  let elem = document.getElementById(idString);
  if (elem) {
    console.log("idString found", elem);
    elem.classList.toggle("coursesShowDetails");
  }
}


const CourseDetails = ({ agency, categoryIndex, courseIndex, details }) => (
  <div className="coursesDetailsWrap">
    <button 
      className="coursesDetailsButton"
      onClick={() => detailsClickHandler(`${agency}${categoryIndex}${courseIndex}Details`)}
      >
      view details
    </button>
    <ul id={`${agency}${categoryIndex}${courseIndex}Details`} className="coursesDetails">
        {
          Object.keys(details).map(detail => (
            <li className="coursesDetailsList">
              <b>{`${detail}`}</b>: 
              {` ${details[detail]}`}
              </li>
          ))
        }
    </ul>
  </div>
)

export default CourseDetails;