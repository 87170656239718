/*
  uploadData(stage:string, filename:string, data:object)

  downloadData(stage:string)

  deleteData(stage:string, filename:string)

  callFetch(method:string, body:object)
*/

/**
 * TODO
 * Create a constructor function that takes in
 *  - dev bucket name
 *  - prod bucket name
 *  - address for the fetch request
 *  - custom text for error messages
 * 
 * Update get, put, and del methods to us stage to 
 *   determine which bucket to target
 * 
 * Add method for resetting the dev bucket to match
 *   the prod bucket
 */

class StorageInterface {
  constructor(devAddress, prodAddress, errMessage){
    this.devAddress = devAddress;
    this.prodAddress = prodAddress;
    this.errMessage = errMessage;
  }

  callFetch(stage, method, body=null) {
    let config = {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }

    let fetchAddress = stage === "dev" ? this.devAddress : this.prodAddress;

    if (body) {
      config.body = JSON.stringify(body);
    }

    return fetch(fetchAddress, config)
    .then(response => response.json())
    .then(response => {

      if (!(response.statusCode && response.statusCode === 200)) {
        return this.errMessage;
      }

      let calendarData;
      
      if (response.body) {
          calendarData = JSON.parse(response.body);
      }

      return calendarData
    })
    .catch(error => {
      console.error(error);
      return this.errMessage;
    });
  }


  async deleteData(stage, filename) {
    let response = await this.callFetch(stage, 'DELETE', { filename });
    return response;
  }


  async downloadData(stage) {
    let response = await this.callFetch(stage, 'GET');
    return response;
  }


  async uploadData(stage, filename, data) {
    let response = await this.callFetch(stage, "POST", { filename, file: data });
    return response;
  }
}


const devAddress = "https://ikgv00tqnh.execute-api.us-east-1.amazonaws.com/beta/storageinterface/dev";
const prodAddress = "https://ikgv00tqnh.execute-api.us-east-1.amazonaws.com/beta/storageinterface/prod";
const errMessage = {
  line1: "Sorry an error occured while accessing storage data",
  line2: "Please try again later or send us an email at kimsha@essentialsafety.nyc"
};


let storageInterface = new StorageInterface(
  devAddress,
  prodAddress,
  errMessage
);




export default storageInterface;