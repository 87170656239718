import React from 'react';
import Category from "./Category";

import courseData from "../../data/coursesData";


const collapseHandler = (agencyName) => {
  const elem = document.getElementById(`${agencyName}categoriesContainer`);
  if (elem) {
    elem.classList.toggle('collapse');
  }
  return
}

  
const Agency = () => (
  <>
    {
      courseData.map(({ agency, category }) => (
        <div id={`${agency}wrapper`} className="coursesAgencyWrapper"  key={`${agency}AgencyWrap`}>
          <h3 className="coursesAgencyNameHeader" onClick={() => collapseHandler(agency)}>{`${agency} Courses`}</h3>
          <div className="collapse coursesCategoriesContainer" id={`${agency}categoriesContainer`}>
            <Category category={category} agency={agency}/>
          </div>
        </div>
      ))
    }
  </>
)

export default Agency;