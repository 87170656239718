import React from 'react';
import { Switch, Route } from 'react-router-dom';
import About from "./Components/About";
import Admin from "./Components/Calendar/Admin";
import Calendar from "./Components/Calendar/Calendar";
import Contact from "./Components/Contact";
import Courses from "./Components/Courses/Courses";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Policies from "./Components/Policies";

import './App.scss';

function App() {
  return (
    <>
      <Header />
      <Switch>
        < Route exact path="/" component={Home} />
        < Route path="/home" component={Home} />
        < Route path="/about" component={About} />
        < Route exact path="/calendar" component={Calendar} />
        < Route exact path="/calendar/admin" component={Calendar} />
        < Route exact path="/calendar/admin/:selectedMonth/:selectedDay" component={Calendar} />
        < Route exact path="/calendar/:selectedMonth/:selectedDay" component={Calendar} />
        < Route exact path="/admin" component={Admin} />
        < Route exact path="/courses" component={Courses} />
        < Route path="/courses/:agency" component={Courses} />
        < Route path="/contact" component={Contact} />
        < Route path="/policies" component={Policies} />
      </Switch>
      <Footer/>
    </>
  );
}

export default App;
