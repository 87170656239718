import React from 'react';
import { daysOfWeek } from "./Month";



const SingleDay = ({adminFuncs, singleDayData, monthName, history, isAdmin}) => {
  let { dayOfWeek, date, holiday, message, events } = singleDayData;

  // const addClassHandler = () => {
  //   console.log(`Add a class to ${monthName} the ${date}`);
  // }

  // const removeClassHandler = (eventNo) => {
  //   console.log(`Remove class ${eventNo} from ${monthName} the ${date}`);
  // }

  // const updateClassHandler = (eventNo) => {
  //   console.log(`Update class ${eventNo} from ${monthName} the ${date}`);
  // }

  const handleCloseSingleDay = () => {
    history.goBack();
  }

  return (
    <div className="singleDayContainer" >
      <div className="singleDayWrap">
        <div className="singleDayHeaderContainer">
          <div className="singleDayHeaderUpperWrap">
            <p className="singleDayHeaderText">{`${daysOfWeek[dayOfWeek].full} ${monthName} ${date}`}</p>
            <i 
              className="singleDayHeaderIcon far fa-window-close" 
              onClick={() => {handleCloseSingleDay()}} >
            </i>

          </div>
          <p className="singleDayHeaderText">{`${events.length} ${events.length > 1 ? "classes" : "class"}`}</p>
        </div>
        {
          holiday ? 
          <>
            <p>{holiday}</p>
            <p>{message}</p>
          </>
          : null
        }
        {
          events.length > 0 ?
          events.map(({courseName, startTime, endTime, locationLine1, locationLine2}, index) => (
            <div className="singleDayClassContainer" key={`${courseName}${index}`}>
              <p className="singleDayClassText"><u>{courseName}</u></p>
              <p className="singleDayClassText">{`From ${startTime} - ${endTime}`}</p>
              <p className="singleDayClassText">{locationLine1}</p>
              <p className="singleDayClassText">{locationLine2}</p>
              {
                isAdmin ?
                <div className="singleDayFooterContainer">
                  <button 
                    className="singleDayButton"
                    onClick={() => adminFuncs.removeClass(index, date) } >Remove Class</button>
                  <button 
                    className="singleDayButton"
                    onClick={() => adminFuncs.updateClass(index, date) } >Update Class</button>
                </div>
                : null
              }
            </div>
          ))
          :
          <div className="singleDayFooterContainer">
            <p className="singleDayClassText">No classes scheduled</p>
          </div>
        }
        {
          isAdmin ?
          <div className="singleDayFooterContainer">
            <button 
              className="singleDayButton"
              onClick={() => typeof adminFuncs.addClass(date) } >Add a Class</button>
          </div>
          : null
        }
      </div>
    </div>
  ) 
}

export default SingleDay;