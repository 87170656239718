/**
 * TODOs
 * create form to create/update the calendar
 *  - date
 *  - class name
 *  - time 
 *  - location
 * auto fill classes from the courseData for class to add to calendar
 * upload the updated calendar data to s3
 * 
 */

import React, { useState, useEffect } from 'react';

import StorageInterface from "../../api scripts/storageInterface";
import CaledarInterface from "../../api scripts/calendarInterface";

import courseData from "../../data/coursesData";


const Admin = ({allCalendarData, viewMonth, viewYear, monthData}) => {

  function radioClickHandler(evt) {
    let unSelectedRadioElemId = evt.target.id === "class-select_radio" ? "class-input_radio" : "class-select_radio";
    let selectedInputElemId = evt.target.id === "class-select_radio" ? "class-select" : "class-input";
    let unSelectedInputElemId = evt.target.id === "class-select_radio" ?  "class-input" : "class-select";
    const unSelectedRadioElem = document.getElementById(unSelectedRadioElemId);
    const selectedInputElem = document.getElementById(selectedInputElemId);
    const unSelectedInputElem = document.getElementById(unSelectedInputElemId);

    unSelectedRadioElem.checked = false;
    selectedInputElem.disabled = false;
    unSelectedInputElem.disabled = true;
  }


  const [daysInMonth, setDaysInMonth] = useState([]);

  function dateSelectHandler(evt) {
    const yearSelectElem = document.getElementById("year-select");
    const monthSelectElem = document.getElementById("month-select");
    if (yearSelectElem.value !== "" && monthSelectElem.value !== "") {
      const dayCount = new Date(yearSelectElem.value, parseInt(monthSelectElem.value) + 1, 0).getDate();
      const buildDaysInMonth = [];
      for (let i = 1; i < dayCount + 1; i++) {
        buildDaysInMonth.push(i);
      }
      setDaysInMonth(buildDaysInMonth);
    }
  }


 function submitHandler (evt) {
  evt.preventDefault();
  console.log("Submitting Form data!", evt);
 }


  useEffect(() => {

  }, [daysInMonth]);


  const selectInfo = [];
  
  (() => {
    courseData.forEach( agency => {
      agency.category.forEach(category => {
        category.courseInfo.forEach(course =>{
          selectInfo.push(course.title)
        })
      })
    })
  })();

  function startTimeChangeHandler(evt) {
    evt.preventDefault();
    const startTimeElem = document.getElementById("startTime-input");
    const stopTimeElem = document.getElementById("stopTime-input");
    console.log(startTimeElem.value, evt);
    // const
  }

  return (
    <div>
      <p>The Form</p>
      <form onSubmit={(evt) => submitHandler(evt) }>
        <label htmlFor="year-select">Select a year</label>
        <select id="year-select" name="year-select" onChange={(evt) => dateSelectHandler(evt)} >
          <option value="">
            Select a year
          </option>
          <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
          <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>

        </select>
        <br/>
        <label htmlFor="month-select">Select a month</label>
        <select id="month-select" name="month-select" onChange={(evt) => dateSelectHandler(evt)} >
            <option value="">Select a month</option>
            <option value={0}>January</option>
            <option value={1}>February</option>
            <option value={2}>March</option>
            <option value={3}>April</option>
            <option value={4}>May</option>
            <option value={5}>June</option>
            <option value={6}>July</option>
            <option value={7}>August</option>
            <option value={8}>September</option>
            <option value={9}>October</option>
            <option value={10}>November</option>
            <option value={11}>December</option>
        </select>
        <br/>
        <label htmlFor="day-select">Select a day</label>
        <select>
          <option value="">Select a Day</option>
          {
            daysInMonth.map(day => <option value={`${day}`} key={`day${day}`}>{day}</option>)
          }
        </select>
        <br/>
        <label htmlFor="class-select">Select a class</label>
        <input 
          type="radio" 
          id="class-select_radio" 
          name="class-select_radio" 
          onClick={(evt) => radioClickHandler(evt)}
           />
        <select id="class-select" name="class-select" disabled>
          { 
            selectInfo.map(course => {
              return <option value={`${course}`} key={course}>{course}</option>
            }
          )}
        </select>
        <br/>
        <label htmlFor="class-input">Type a class</label>
        <input 
          type="radio" 
          id="class-input_radio" 
          name="class-input_radio" 
          onClick={(evt) => radioClickHandler(evt)} />
        <input type="text" id="class-input" name="class-input" disabled/>
        <br/>
        <label htmlFor="startTime-input">Start Time</label>
        <input
          type="time" 
          id="startTime-input" 
          name="startTime-input" 
          onChange={(evt) => startTimeChangeHandler(evt)} />
        <br/>
        <label htmlFor="stopTime-input">End Time</label>
        <input 
          type="time"
          id="stopTime-input"
          name="stopTime-input" />
        <br/>
        <label htmlFor="location-input">Class Location</label>
        <input type="text" id="location-input" name="location-input" />
        <br/>
        <input type="submit" value="Schedule Class" />
      </form>
    </div>
  )
}

export default Admin;