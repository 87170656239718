import React from 'react';
import Day from './Day';

const Week = ({daysInWeek, weekNum, monthName, history, match}) => {

    return (
        <div className="weekWrap">
            {
                daysInWeek.map((dayData, index) => (
                    <Day 
                        key={`week${weekNum}day${index + 1}`} 
                        dayData={dayData} 
                        monthName={monthName} 
                        history={history}
                        match={match} 
                    />
                ))
            }
        </div>
    )
}

export default Week;