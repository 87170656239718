import React from 'react';
import ContactFormModal from "./ContactFormModal";



const ContactForm = ({submitHandler, hideModal, show, message}) => {

	function formClickHandler() {
		if (show) hideModal();
	}

	return (
		<div id='messageWrap' className="pageLayout">
				<ContactFormModal show={show} message={message}/>
				<form id='contactForm' 
							className='contactForm' 
							method='post' 
							action='' 
							onClick={() => formClickHandler()}>
					<input name='name' 
								type='text' 
								className='contactInput' 
								id='inputName' 
								placeholder='Your Name (required)' 
								required></input>
					<input name='email' 
								type='text' 
								className='contactInput' 
								id='inputEmail' 
								placeholder='Your Email (required)' 
								required></input>
					<input name='subject' 
								type='text' 
								className='contactInput' 
								id='inputSubject' 
								placeholder='Subject  (required)' 
								required></input>
					<textarea name='message' 
										className='contactInput' 
										id='inputMessage' 
										placeholder='Message' 
										required></textarea>
					<input type='button' 
								id='contactSend' 
								className='buttons' 
								value='Send Message' 
								onClick={(evt) => submitHandler(evt)}></input>
				</form>
			</div>
	)}

export default ContactForm;