import React, { Component } from 'react';
// import SectionImg from "./SectionImg";

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      indexNo: 1,
      visibleElemId: `${this.props.sliderName}ImgOne`,
      intervalId: "",
    }
  }

  componentDidMount() {
    this.startSlides();
  }

  componentWillUnmount() {
    this.stopSlides();
  }

  startSlides() {
    let counter = 0;
    const timerId = setInterval(() =>{
      counter += 1;
      if (counter === 28) {
        this.fade();
      } else if (counter === 29) {
        this.updateSlide();
      } else if (counter > 29) {
        counter = 0;
      }

    },500);
    this.setState({intervalId: timerId});
  }

  stopSlides() {
    clearInterval(this.state.intervalId);
  }

  fade() {
    const { visibleElemId } = this.state;
    const { sliderName } = this.props;

    const fadeInElem = visibleElemId === `${sliderName}ImgOne` ? 
    document.getElementById(`${sliderName}ImgTwo`) : document.getElementById(`${sliderName}ImgOne`);
    fadeInElem.classList.remove('sliderPicFadeOut');
    fadeInElem.classList.add('sliderPicFadeIn');
    
    const fadeOutElem = visibleElemId === `${sliderName}ImgOne` ? 
    document.getElementById(`${sliderName}ImgOne`) : document.getElementById(`${sliderName}ImgTwo`);
    fadeOutElem.classList.remove('sliderPicFadeIn');
    fadeOutElem.classList.add('sliderPicFadeOut');

    this.setState((prevState) => {
      return ({ 
        visibleElemId: prevState.visibleElemId === `${sliderName}ImgOne` ? `${sliderName}ImgTwo` : `${sliderName}ImgOne`
      })
    });
  }

  updateSlide() {
    const { pics, sliderName } = this.props;

    this.setState((prevState) => {
      const currentIndex = prevState.indexNo >= pics.length - 1 ? 0 : prevState.indexNo + 1;
      const hiddenElemId = this.state.visibleElemId === `${sliderName}ImgOne` ? `${sliderName}ImgTwo` : `${sliderName}ImgOne`;
      const updatePicElem = document.getElementById(`${hiddenElemId}`);
      updatePicElem.src = pics[currentIndex];
      return ({
        indexNo: currentIndex
      })
    });
  }



  render() {
    const { sliderName, pics } = this.props;
    return (
      <div className="sliderContainer">
        <img id={`${sliderName}ImgOne`} className="sliderPic sliderPicOne sliderPicFadeIn" src={pics[0]} alt={`${sliderName}`}/>
        <img id={`${sliderName}ImgTwo`} className="sliderPic sliderPicTwo sliderPicFadeOut" src={pics[1]} alt={`${sliderName}`}/>
      </div>
    )
  }

}

export default Slider;