import React from 'react';

const SectionImg = ({ title, pageName }) => {
    return (
      <div id="sectionImg" className={`sectionFrame sectionFrame${pageName}Image`}>
        {title ? <p className="pageTitle" >{title}</p>: null}
      </div>
    )
  }

export default SectionImg;