import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import linkInfo from '../data/navBarData';


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenu: false
    }

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      mobileNavElem: document.getElementById('mobileNav'),
      menuIconElem: document.getElementById('menuIcon')
    })
  }

  closeMobileMenu() {
    this.state.mobileNavElem.classList.remove('mobileNavDropEnd');
    this.state.mobileNavElem.classList.add('mobileNavDropStart');
    let elem = this.state.menuIconElem;
    elem.style.color = 'black';
    this.setState({mobileMenu: false});
  }

  openMobileMenu() {
    this.state.mobileNavElem.classList.remove('mobileNavDropStart');
    this.state.mobileNavElem.classList.add('mobileNavDropEnd');
    let elem = this.state.menuIconElem;
    elem.style.color = 'white';
    this.setState({mobileMenu: true});
  }

  handleMouseLeave() {
    this.closeMobileMenu();
  }

  handleMenuClick() {
    if (this.state.mobileMenu === true) this.closeMobileMenu();
    else this.openMobileMenu();
  }

  handleLinkClick() {
    this.closeMobileMenu();
  }

  render() {
    return (
      <header id='header' className="headerContainer" onMouseLeave={ () => this.handleMouseLeave()}>
          <div className="headerCompanyInfoBorder">
            <div className="headerCompanyInfoContainer">
              <div className="headerCompanyInfoWrap">
                <p className="headerInfoText">
                  <span className="contactIcons fas fa-phone"></span> &nbsp;
                  (917) 416-5465
                </p>
                <p className="headerInfoText">
                  <span className="contactIcons far fa-envelope"></span> &nbsp;
                  kimsha@essentialsafety.nyc
                </p>
              </div>
            </div>
          </div>
          <div className="lowerHeaderWrap">
            <div className="headerBrandContainer">
              <div id="headerBrandWrap" className="headerBrandWrap">
                <img className="siteBrand" src={require('..\\Assets\\thumbnail_logo.jpg')} alt="site brand" />
                <p className="headerBrandName">ESSENTIAL SAFETY COMMUNICATION INC.</p>
                <i id="menuIcon" className="headerMenuIcon fas fa-bars" onClick={ ()=> this.handleMenuClick()}></i>
              </div>
              <nav id="mobileNav" className="headerMobileNav mobileNavDropStart">
                <ul id="navBarLinks">
                  {linkInfo.map( ({id, link, name}) => <li onClick={() => this.handleLinkClick()} key={id}> <NavLink className="headerMobileNavAnchors" exact to={link}>{name}</NavLink> </li>)}
                </ul>
              </nav>
            </div>
          </div>
      </header>
    )
  }

}

export default Header