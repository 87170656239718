//id is the key and li element's id
//name is the text that will appear for the link
//link is the value given to the href attribute

const linkInfo = [
  {id: 'home', name: 'Home', link: '/'},
  {id: 'about', name: 'About', link: '/about'},
  {id: 'courses', name: 'Courses', link: '/courses'},
  {id: 'contact', name: 'Contact', link: '/contact'},
  {id: 'calendar', name: 'Calendar', link: '/calendar'}
]

export default linkInfo;