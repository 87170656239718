import React, { useEffect } from 'react';
import SectionImg from '../SectionImg';
import Agency from './Agency';


const collapseHandler = (agencyName) => {
  const elem = document.getElementById(`${agencyName}categoriesContainer`);
  if (elem) {
    elem.classList.toggle('collapse');
  }
  return
}

 
const Courses = ({match}) => {
  useEffect(() => {
    if (match?.params?.agency) {
      collapseHandler(match.params.agency);
    }
  });

  return (
    <div className="courses">
      <SectionImg title="Our Courses" pageName="Courses" imgClass="defaultSectionImg" />
      <div className="coursesContainer">
        <img className="coursesImg" alt="student learning about safety" src={require("..\\..\\Assets\\images\\thumbnail_IMG_5658.jpg")} />
        <div className="coursesWrapper">
          <Agency />
        </div>
      </div>
    </div>
  )
}

export default Courses;