export const pics = [
  require("..\\Assets\\images\\classes\\IMG_2392.jpg"),
  require("..\\Assets\\images\\classes\\IMG_2510.jpg"),
  require("..\\Assets\\images\\classes\\IMG_2652.jpg"),
  require("..\\Assets\\images\\classes\\IMG_3200.jpg"),
  require("..\\Assets\\images\\classes\\IMG_3311.jpg"),
  require("..\\Assets\\images\\classes\\IMG_7647.jpg"),
  require("..\\Assets\\images\\classes\\IMG_7650.jpg")
]

export const agencyInfo = [
  {
    name: 'IACET',
    description: 'The International Accreditors for Continuing Education and Training (IACET) developed the original Continuing Education Unit (CEU) and today ensures that providers of continuing education and training can prove they provide high-quality instruction by following the ANSI/IACET 2018-1 Standard for Continuing Education and Training through a rigorous accreditation process.',
    classesAvailable: false,
    logo: require('..\\Assets\\logos\\IACET.png')
  },
  {
    name: 'OSHA',
    description: 'With the Occupational Safety and Health Act of 1970, Congress created the Occupational Safety and Health Administration (OSHA) to ensure safe and healthful working conditions for working men and women by setting and enforcing standards and by providing training, outreach, education and assistance.',
    classesAvailable: true,
    logo: require('..\\Assets\\logos\\osha logo.svg')
  },
  {
    name: 'ATSSA',
    description: 'ATSSA was incorporated in 1970 as an International Trade Association. ATSSA is a member partnership comprised of approximately 1,500 companies representing over 11,000 industry professionals in the sign manufacturing, pavement marking, guardrail and barrier, traffic services, and traffic signals technical divisions.',
    classesAvailable: true,
    logo: require('..\\Assets\\logos\\ATSSA logo.svg')
  },
  {
    name: 'DOB',
    description: 'The Department of Buildings promotes the safety of all people that build, work, and live in New York City by regulating the lawful use of over one million buildings and construction sites across the five boroughs. With a focus on safety, service, and integrity, the Department enforces the City’s Construction Codes, Zoning Resolution, and the New York State Multiple Dwelling Law.',
  classesAvailable: true,
    logo: require('..\\Assets\\logos\\NYC_buildings logo.svg')
  },
  {
    name: 'NCCER',
    description: 'NCCER is a not-for-profit 501(c)(3) education foundation created in 1996 as The National Center for Construction Education and Research. It was developed with the support of more than 125 construction CEOs and various association and academic leaders who united to revolutionize training for the construction industry.',
  classesAvailable: false,
    logo: require('..\\Assets\\logos\\nccer logo.png')
  },
]
