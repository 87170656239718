import React from 'react';
import { Route } from 'react-router-dom';
import Week from "./Week";
import SingleDay from './SingleDay';


export let daysOfWeek = {
  0: { full: 'Sunday', abrv: 'Sun' },
  1: { full: 'Monday', abrv: 'Mon' },
  2: { full: 'Tuesday', abrv: 'Tues' },
  3: { full: 'Wednesday', abrv: 'Weds' },
  4: { full: 'Thursday', abrv: 'Thurs' },
  5: { full: 'Friday', abrv: 'Fri' },
  6: { full: 'Saturday', abrv: 'Sat' }
}

function Month (props) {
  let { history, match, adminFuncs, monthInWeeks, monthData } = props;
  let { selectedMonth, selectedDay } = match.params;
  let singleDayData;

  if (selectedMonth !== undefined && selectedDay !== undefined) {
    singleDayData = monthData.days[selectedDay - 1];
  }

  let monthName = monthData.monthName;
  let year = monthData.year;


  return (
    <div className="pageLayout calendarContainer">
      <div className="calendarHeaderWrap">
        <div className="monthSelectIconContainer"><i className="monthSelectIcon fas fa-angle-left"></i><p>Previous</p></div>
        <h1>{`${monthName} ${year}`}</h1>
        <div className="monthSelectIconContainer"><p>Next</p><i className="monthSelectIcon fas fa-angle-right"></i></div>
      </div>
      <div className="calendarWrapOutter">
        <Route 
          exact path="/calendar/:selectedMonth/:selectedDay" 
          render={ () => <SingleDay
            singleDayData={singleDayData}
            monthName={monthName}
            history={history} 
            isAdmin={false} /> }
        />

        <Route 
          exact path="/calendar/admin/:selectedMonth/:selectedDay" 
          render={ () => <SingleDay
            adminFuncs={adminFuncs}
            singleDayData={singleDayData}
            monthName={monthName}
            history={history} 
            isAdmin={true} /> }
        />

        <div className="dayWrapContainer">
          {
            Object.values(daysOfWeek).map(dayOfWeek => (
              <div className="dayWrapFormat dayWrapHeader"key={`${dayOfWeek.full}Header`}>
                <p className="dayWrapHeaderTextFull">{dayOfWeek.full}</p>
                <p className="dayWrapHeaderTextAbrv">{dayOfWeek.abrv}</p>
              </div>
            ))
          }
        </div>
        <div className="calendarWrapInner">
          {
            monthInWeeks.map((week, index) => <Week 
              key={`week${index}`} 
              daysInWeek={week} 
              weekNum={index} 
              monthName={monthName} 
              history={history} 
              match={match} />)
          }
        </div>
      </div>
    </div>
  )
}

export default Month;