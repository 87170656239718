import React from 'react';

const Event = ({event}) => {
  let { courseName, startTime, endTime, locationLine1, locationLine2} = event;
  return (
    <div className="eventWrapper" title="Click for more info">
      <p className="calendarText"><u><b>{`${courseName}`}</b></u></p>
      <p className="calendarText">{`${startTime} - ${endTime}`}</p>
      {/* <p className="calendarText">{locationLine1}</p>
      <p className="calendarText">{locationLine2}</p> */}
    </div>
  )
}

export default Event;