import React from 'react';
import CourseInfo from "./CourseInfo";
  

const Category = ({ category, agency }) => (
  <>
    {
      category.map(({ categoryName, overview, courseInfo }, categoryIndex) => (
        <div className="categoryWrapper" key={`${categoryName}CategoryWrap`}>
          <h4>{categoryName}</h4>
          <p>{overview}</p>
          <ul>
            <CourseInfo 
              courseInfo={courseInfo} 
              agency={agency} 
              categoryIndex={categoryIndex} 
              />
          </ul>
        </div>
      ))
    }
  </>
)

export default Category;