import React, { useRef, useLayoutEffect, useState } from 'react';
import Event from './Event';
import Holiday from './Holiday';

const dayClickHandler = (date, clickable, monthName, history, isAdmin) => {
  if (clickable) {
    history.push(`/calendar/${isAdmin ? "admin/": ""}${monthName}/${date}`);
  } else {
    return
  }
}


const handleHover = (overflowCheck, dateId) => {
  if (overflowCheck) {
    document.getElementById(dateId).classList.toggle("dayWrapOverflowFade");
  }
}


const Day = ({ dayData, monthName, history, match }) => {
  let { date, holiday, message, events } = dayData;
  const isAdmin = match.path === "/calendar/admin";
  const clickable = date !== null && (events.length > 0 || holiday || isAdmin);
  const [overflowCheck, setOverflowCheck] = useState(false);
  const dateId = `day${date}`;
  const dayElem = useRef(null);

  useLayoutEffect(() => {
    if (dayElem.current.clientHeight < dayElem.current.scrollHeight) {
      setOverflowCheck(true);
    }
  },[overflowCheck]);

  return (
    <>
      <div 
        className={`dayWrapFormat dayWrap ${clickable ? "dayWrapCursor": ""}`}
        ref={dayElem}
        onClick={() => dayClickHandler(
          date, 
          clickable,
          monthName, 
          history,
          isAdmin)}
        onMouseEnter={() => handleHover(overflowCheck, dateId) }
        onMouseLeave={() => handleHover(overflowCheck, dateId) }>
        <p className="calendarHeaderText">{date}</p>
        <Holiday holiday={holiday} message={message} />
        {
          events.map((event, index) => (
            <Event 
              key={`day${date}Event${index + 1}`} 
              event={event} 
            />
          ))
        }
        {
          overflowCheck ?
          <p id={dateId} className="dayWrapOverflow">{`Click to see more`}</p>
          :
          null
        }
      </div>
    </>
  )  
}

export default Day;