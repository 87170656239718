const courseData = [
  {
    agency: "OSHA",
    category: [
      {
        categoryName: "Safety Outreach Training",
        overview: "We teach workers how to identify and avoid common safety and health risks in the workplace. We provide OSHA-authorized training to workers in construction and general industry. The 10-hour courses are designed for entry-level workers, and 30-hour courses are for supervisors or workers with safety responsibilities.",
        courseInfo: [
        {title: "10 hour - Construction", details: { }},
        {title: "30 hour - Construction", details: { }},
        {title: "10 hour - General Industry", details: { }},        
        {title: "30 hour - General Industry", details: { }},        
        ]
      }
    ]
  }, 
  {
    agency: "ATSSA",
    category: [
      {
        categoryName: "Flagger Training",
        overview: `Learn how to be a safe and effective flagger with ATSSA's flagger training courses. Understand why proper flagger operations are important and learn the standard skillset of a good flagger. Apply and identify standard flagger control references and learn standard flagger practices for various situations.`,
        courseInfo: [
        {title: "4 hour - flagging", details: { }}
        ]
      }
    ]
  },
  {
    agency: "DOB",
    category: [
      {
        categoryName: "Safety Courses",
        overview: "",
        courseInfo: [
          {
            title: "40-Hour Site Safety Manager", 
            details: { 
              Description: "This course is a comprehensive review of Chapter 33 and the Rules of the City of New York that pertain to public protection during construction. The purpose of the course is to provide training with regards to construction on major buildings, that may be classified by the New York City Building Department, with emphasis on providing not only safety for the public and adjoining property, but to provide safety for worker within the job site as per OSHA requirements. This course is one of the pre-requisites an applicant must successfully complete before being eligible to apply for Site Safety Manager Certificate Examination. In addition, this course fulfills one of the training course requirements for Site Safety Coordinator and Construction Superintendent Certification in New York City depending on experience. The goal of this course is to provide participants with knowledge of the code requirements of the New York City Buildings Department, New York City Rules, to provide Pedestrians, Adjacent Buildings and Worker with safe work environment both in and around construction sites.", 
              CEU: "4.0", 
              Length: "40 Hours", 
              Languages: "English", 
              Price: "$750.00"
            }
          },
          {
            title: "8-Hour Site Safety Manager Refresher / Chapter 33", 
            details: { 
              Description: "This course is a refresher to the 40-Hour Site Safety Manager Course, which is a comprehensive presentation of Chapter 33 and the public protection-pertaining Rules of the City of New York. Students will review the hazards of working in the NYC construction environment and the necessary methods and work practices on an urban construction site. This program is most applicable for:  Experienced Construction Workers, Supervisors, Other personnel with worker and public safety responsibility for construction activities with general knowledge of the NYC Building Codes, Individuals applying for a Site Safety Training Card.", 
              CEU: "0.8", 
              Length: "8 Hours", 
              Languages: "English, Spanish", 
              Price: "$220.00"
            }
          },
          {
            title: "8-Hour Site Safety Coordinator",  
            details: { 
              Description: "The 8 hour Site Safety Coordinator Course is a shortened version of the 40 hour Site Safety Manager course for licensed architects and engineers, and workers with experience in construction supervision or safety professionals. It is a comprehensive review of Chapter 33 and the Rules of the City of New York that pertain to public protection. This course provides students with safe job practices in New York City. They will be introduced to the hazards of working in the New York City construction environment and the specific methods and work practices that are necessary on an urban construction site. This course is one of the requirements all applicants must successfully complete in order to apply for Site Safety Coordinator or to register or renew for a Construction Superintendent license with the New York City Department of Buildings. Students will receive a certificate of completion.", 
              CEU: "0.8", 
              Length: "8 Hours", 
              Languages: "English, Spanish", 
              Price: "$250.00"
            }
          }
        ]
      },
      {
        categoryName: "Scaffold Courses",
        overview: "",
        courseInfo: [
          {
            title: "4-Hour Supported Scaffold User & Refresher", 
            details: { 
              Description: "Course Description: This course covers the basic elements of supported scaffold safety and introduces students to the nature of supported scaffold hazards. It is applicable for any individual who needs to use or work on a Supported Scaffold in NYC. Particular emphasis is placed on the role of the scaffold user in accident prevention, including: Scaffold inspections, fall protection, recognizing hazards, Responding to emergencies.", 
              CEU: "0.4", 
              Length: "4 Hours", 
              Languages: "English, Spanish", 
              Price: "$150.00"
            }
          },
          {
            title: "8-Hour Supported Scaffold Installer & Remover Refresher", 
            details: { 
              Description: "This course is a requirement for an individual to maintain his/her designation as a Supported Scaffold Installer & Remover in NYC. In this course, we will cover topics such as; general overview of scaffolding, OSHA 1926 review, NYC Construction Codes Overview, DOB Overview, general principles of fall protection, PPE & Fall arrest system. Students will also cover the necessary safety checklist items from Pre-Start, Erection & Dismantling.", 
              CEU: "0.8", 
              Length: "8 Hours", 
              Languages: "English, Spanish", 
              Price: "$220.00"
            }
          },
          {
            title: "8-Hour Suspended Scaffold User Refresher",  
            details: { 
              Description: "Students, in this course will learn how to properly inspect the necessary scaffold equipment. They will also be able to identify the basic scaffold components, execute safe installation and removal of the equipment.", 
              CEU: "0.8", 
              Length: "8 Hours", 
              Languages: "English, Spanish", 
              Price: "$220.00"
            }
          },
          {
            title: "8-Hour Suspended Scaffold Supervisor Refresher",  
            details: { 
              Description: "In this course, it is a requirement for an individual to maintain his/her designation as a suspended scaffold supervisor in NYC.", 
              CEU: "0.8", 
              Length: "8 Hours", 
              Languages: "English, Spanish", 
              Price: "$220.00"
            }
          }
        ]
      },
      {
        categoryName: "SST Prescribed Courses",
        overview: "Site Safety Training (SST) Courses",
        courseInfo: [
          {
            title: "2-Hour Site Safety Plan (SSP)", 
            details: { 
              Description: "Course Description: This is an elective course that can help fulfill the requirement for an individual applying for a Site Safety Training Card. This is an awareness training only and does not provide any other qualification or authorization outside of the Site Safety Training Card. Some topics covered in this course include; fences & gates, viewing panels, use of flaggers, crane swings & staging areas, sheeting/shorting, foundation/excavation, lot dimension and lot lines (meets & bounds) horizontal netting, ramps (ratio: slope) and many more. This course is part of the curriculum to satisfy SST 32 Supervisor Card – refer to for pricing.  ", 
              CEU: "0.2", 
              Length: "2 Hours", 
              Languages: "English, Spanish", 
              Price: "$65.00"
            }
          },
          {
            title: "2-Hour Drug and Alcohol Awareness", 
            details: { 
              Description: "This course addresses substance and alcohol abuse in the workplace, particularly focusing on safety and productivity hazards caused by it. This course is required for any worker or supervisor employed within the Five Boroughs of NYC on projects requiring a Construction Super, Site Safety Manager, Site Safety Coordinator, Concrete Safety manager, or Competent Person. It is also applicable to those seeking a more thorough understanding of their role in initiating and maintaining drug-free workplace policies. This course is part of the curriculum to satisfy SST 10 and 32 Supervisor Card refer to for pricing.", 
              CEU: "0.2", 
              Length: "2 Hours", 
              Languages: "English, Spanish", 
              Price: "$65.00"
            }
          },
          {
            title: "2-Hour Pre-Task Meeting ", 
            details: { 
              Description: "Course Description: This is a general elective course that can help fulfill the requirement for an individual applying for a site safety training card. This is an awareness level training only and does not provide any other qualification or authorization outside of the Site Safety Training Card. In this course, students will learn topics such as; Pre-Task meeting necessities, Roles & Responsibilities of a Competent Person, Hierarchy of Controls, Components of a Pre-Task Meeting, Number of Pre-Task Meetings, Potential Human Error Traps when Performing Pre-Task Meetings and many more. This course is part of the curriculum to satisfy SST 32 Supervisor Card.", 
              CEU: "0.2", 
              Length: "2 Hours", 
              Languages: "English, Spanish", 
              Price: "$65.00"
            }
          },
          {
            title: "2-Hour Tool-Box Talks", 
            details: { 
              Description: "Course Description: this course is a general elective course that can help fulfill the requirement for an individual applying for a site safety training card. This is an awareness level training only and does not provide any other qualification or authorization outside of the site safety training card. Some topics covered in this module are; Roles & Responsibilities of a Competent Person, Potential Hazards, Where to Perform Toolbox Talks and many more. This course is part of the curriculum to satisfy SST 32 Supervisor Card.", 
              CEU: "0.2", 
              Length: "2 Hours", 
              Languages: "English, Spanish", 
              Price: "$65.00"
            }
          },
          {
            title: "4-Hour Fall Prevention", 
            details: { 
              Description: "Course Description: This awareness-level only training course is intended to educate how to avoid accidents and injuries while working on roofs, leading edges, ladders or scaffolds by defining the regulations and safety rules associated with fall hazards on construction sites. An overview of essential fall hazards and prevention, will be covered in this section under the OSHA and DOB regulations. This program is most applicable for any personnel that may be exposed to heights or work around fall hazards in construction sites.", 
              CEU: "0.4", 
              Length: "4 Hours", 
              Languages: "English, Spanish", 
              Price: "$150.00"
            }
          },
          {
            title: "8-Hour Fall Prevention", 
            details: { 
              Description: "This awareness level is a prerequisite for some construction, demolition workers and supervisors at certain construction sites within 5 boroughs of NYC to obtain a Site Safety Training card. Trainees will become generally familiar with terms associated with fall hazards and controls in the construction industry as they pertain to construction in New York City; will be able to recognize and avoid fall hazards and prevent fall-related incidents from occurring or causing harm; learn of roles, responsibilities and accountabilities of various individuals on a construction site. Course curriculum also includes a review of regulatory safety requirements associated with falls and decision-making when working at heights.", 
              CEU: "0.8", 
              Length: "8 Hours", 
              Languages: "English, Spanish", 
              Price: "$225.00"
            }
          }
        ]
      },
      {
        categoryName: "SST General Electives",
        overview: "Site Safety Training (SST) Courses",
        courseInfo: [
          {
            title: "1-Hour Electrocution Prevention", 
            details: { 
              Description: "This course is a general elective course that can help fulfill the requirement for an individual applying for a Site Safety Training Card. THIS IS AN AWARENESS LEVEL TRAINING ONLY and does not provide any other qualification or authorization outside of the Site Safety Training Card. Trainees will become generally familiar with terms and the phenomenon of electricity and how it causes harm, and will be able to recognize electrical hazards and error traps associated with the potential for electricity to do harm. Trainees should also become aware of administrative regulatory safety requirements associated with electricity.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Fire Protection and Prevention", 
            details: { 
              Description: "1-Hour Fire Protection And Prevention training course will describe the different types of fires and how to extinguish them safely to prevent damages and injuries on the work site.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour First Aid and CPR", 
            details: { 
              Description: "This 1-Hour First Aid And CPR training course will cover the 	importance of having a first aid kit on hand at all construction sites in case of an 	emergency, describes the legal issues of applying first aid, goes over the required 	components of a first aid kit, and describes when and how to administer cardio-	pulmonary resuscitation or CPR.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Handling Heavy Materials and Proper Lifting Techniques ", 
            details: { 
              Description: "This course is a general elective course students will learn about proper lifting and handling techniques for different types of heavy materials. They will also learn about the signs of overexertion and musculoskeletal stress caused by improper lifting and handling. Students will learn how to avoid injuries from improper lifting and handling, such as back sprains, pulled muscles, joint injuries, spinal injuries, hernias, and struck-by accidents.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Materials Handling, Storage, Use and Disposal", 
            details: { 
              Description: "As a construction worker, it’s important to understand how to prevent fire hazards, fall hazards, struck-by hazards, and control pests. In order to do this, workers must learn how to safely handle, store, use, and dispose of different materials.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Protection from Sun Exposure", 
            details: { 
              Description: "The 1-Hour Protection From Sun Exposure covers the different dangers related to heat and sun exposure, as well as how to prevent these dangers.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Repetitive Motion Injuries ", 
            details: { 
              Description: "The 1-Hour Repetitive Motion Injuries training course teaches 	about what repetitive motions are and the consequences of them.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Stairways and Ladders", 
            details: { 
              Description: "This SST course covers the different hazards associated with using stairways and ladders, and how to prevent different types of injuries from them.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Tools – Hand and Power", 
            details: { 
              Description: "In this SST course, students will learn to recognize hazards and how to safely use a variety of hand and power tools. Students will learn about the potential injuries and health conditions caused by the misuse of different tools, and how to properly use and store tools to prevent accidents.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          }
        ]
      },
      {
        categoryName: "SST Specialized Elective Courses",
        overview: "Site Safety Training (SST) Specialized Elective Courses",
        courseInfo: [
          {
            title: "1-Hour Asbestos / Lead Awareness ", 
            details: { 
              Description: "During this course students will become familiar with the potential health hazards in the workplace from asbestos and lead based products. Students will learn how to recognize the places and locations where asbestos and lead are found in the construction industry in materials and also become aware of safety requirements associated with asbestos and lead on jobsites and in the workplace.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Confined Space Entry ",
            details: { 
              Description: "this course is designed for safety training awareness purposes only, and is not permitted for workers who need to enter confined or permit entry spaces.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Concrete and Masonry Construction",  
            details: { 
              Description: "In the 1-Hour Concrete and Masonry Construction training course, students will learn about the different processes involved in casting concrete, different hazards associated with casting concrete, and how to avoid health issues from working with concrete.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Demolition Safety",  
            details: { 
              Description: "During the 1-Hour Demolition Safety training course, students will learn about the different hazards associated with demolition activities, how to recognize signs of these hazards, the different methods and practices for demolition activities, as well as the required safety protocols to keep the environment free from hazards to avoid injury.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Ergonomics",  
            details: { 
              Description: "In the ergonomics training course, students will learn about the importance and application of ergonomics in the workplace. We will cover the proper use of tools, equipment, and machine controls; proper lifting techniques, and proper ways to move between tasks. This OSHA training course also covers how to avoid pain and injury, recognizing the early signs of musculoskeletal disorders (MSDs), reporting MSDs, and reporting and treating work-related injuries or illness.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Excavations",  
            details: { 
              Description: "If your construction team works on excavation or underpinning activities, it’s important for them to understand the different terms associated with the activities.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Job Hazard Analysis",  
            details: { 
              Description: "The 1-Hour Job Hazard Analysis training course is perfect for learning about a variety of potential hazards on the construction site.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Personnel Lifts / Aerial Lifts / Scissor Lifts and Mobile Scaffolds",  
            details: { 
              Description: "This is an awareness level training only and does not provide any other qualification or authorization outside of the site safety training card. Topics covered in this section will include; different type of personnel lifts associated with hazards, falls from lift to lower level, struck by falling objects from personal lifts, electrical hazards, loading, operating traveling and overhead protection when using lifts.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Motor Vehicles, Mechanized Equipment and Marine Operations; Rollover Protective Structures and Overhead Protection; and Signs, Signals and Barricades",  
            details: { 
              Description: "In the 1-Hour Motor Vehicles, Mechanized Equipment And Marine Operations; Rollover Protective Structures And Overhead Protection; Signs, Signals, And Barricades training course, students will learn about different types of mechanized equipment, terms associated with the equipment, and safety controls they should use when operating such equipment.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Risk Assessment and Accident Investigation",  
            details: { 
              Description: "In the 1-Hour Risk Assessment And Accident Investigation training course, students will learn about the difference between an accident and an incident, what a near miss is, the signs of a deficient observation, identify direct and indirect causes, assess contributing factors, discover the root causality, discuss the “tempting but,” review objectivity versus subjectivity, and go over proactive and reactive decision-making.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "1-Hour Health and Safety Programs in Construction",  
            details: { 
              Description: "In the 1-Hour Health and Safety Programs In Construction training course, students will become familiar with the different Health And Safety Programs or HASP.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          },
          {
            title: "2.50-Hour Foundations for Safety Leadership ",  
            details: { 
              Description: "If you’re trying to improve your leadership skills, then the 2.5-	Hour Foundations for Safety Leadership training course is the perfect way to improve 	your leadership techniques.", 
              CEU: "0.1", 
              Length: "1 Hour", 
              Languages: "English, Spanish", 
              Price: "$50.00"
            }
          }
        ]
      }
    ]
  }
]

export default courseData;