import React, { Component } from 'react';
import HomeHeader from "./HomeHeader";


import '../scss/style.scss';

class Home extends Component{
  constructor(props) {
    super(props);
    this.state = {
      testimonialIndex: 0,
      testimonials: [
        {
          quote: "Kimsha from Essential Safety did a great job. She came to my office and set up gave the training, and my guys finished and they really learned a lot.",
          author: "William",
          company: "ESC Cabling INC."
        },
        {
          quote: "Kimsha from Essential Safety is Fantastic. Not only did I go to get my 30 hour, I have sent all of my employees. She has even come to my office to handle the training. You won’t find anyone better A++",
          author: "Everett",
          company: "Urban Ecospaces, INC."
        },
        {
          quote: "Kimsha from Essential Safety is so accommodating. I wasn’t sure what I wanted, she came to my office and sat down with me to figure out my needs. I was grateful for the resolution and the prompt instruction she gave my staff, Thanks.",
          author: "Rosemary",
          company: "URI INC."
        },
      ]
    }
  }

  componentDidMount() {
    this.startTestimonials();
  }

  componentWillUnmount()  {
    clearInterval(this.state.timerId);
  }

  startTestimonials() {
    let counter = 0;

    const timerId = setInterval( () => {
      counter += 1;
      if (counter <= 1) {
        this.fadeIn();
      }else if (counter === 29) {
        this.fadeOut();
      } else if (counter > 29){
        this.nextQuote();
        counter = 0;
      }

    }, 500);

    this.setState(() => ({
      timerId: timerId
    }));
  }

  nextQuote() {
    this.setState((prevState) => {
      const nextIndex = prevState.testimonials.length - 1 === prevState.testimonialIndex ? 0 : prevState.testimonialIndex + 1;
      return {testimonialIndex: nextIndex}
    })
  }

  fadeIn() {
    const testimonialsElem = document.getElementById('testimonials');
    testimonialsElem.classList.add('fadeIn');
    testimonialsElem.classList.remove('fadeOut');
  }

  fadeOut() {
    const testimonialsElem = document.getElementById('testimonials');
    testimonialsElem.classList.add('fadeOut');
    testimonialsElem.classList.remove('fadeIn');
  }


  render() {
    return (
      <div className="App">
        <HomeHeader imageSrc={require("..\\Assets\\logos\\thumbnail_logo.svg")} title="WE HAVE YOUR SAFETY RIGHT IN THE PALM OF OUR HANDS" pageName="Home" imgClass="defaultSectionImg" />
        <div className="pageLayout homeGistContainer">
          <div className="homeGistSectionWrap">
            <h3 className="homeGistHeader">
            People always want security; here at Essential Safety Communication we can give you that!
            </h3>
          </div>
          <div className="homeGistSectionWrap">
            <p className="homeGistText">
              We stand by our services; we work hard to make sure you’re safe to the greatest degree possible. We will provide your Employees a knowledge to ensure all mechanical and physical aspects are covered for personal safety and health. We offer a full spectrum of services in keeping with the highest standards.
            </p>
          </div>
        </div>
        <div className="pageLayout homeTestimonialsContainer">
            <div className="homeTestimonialsHeaderWrap">
              <h3 className="homeTestimonialsHeader">
                Testimonials
              </h3>
              <hr />
            </div>
            <div id="testimonials" className="homeTestimonialsQuoteWrap">
              <q id="testimonialsQuote" className="homeTestimonialsQuote">{this.state.testimonials[this.state.testimonialIndex].quote}</q>
              <cite>-<span id="testimonialsAuthor" className="homeTestimonialsAuthor">{this.state.testimonials[this.state.testimonialIndex].author}</span>,<span  id="testimonialsCompany" className="homeTestimonialsCompany">{this.state.testimonials[this.state.testimonialIndex].company}</span> </cite>
            </div>
        </div>
      </div>
    )
  }
} 

export default Home;