import React from "react";
import CourseDetails from "./CourseDetails";


const CourseInfo = ({ courseInfo, agency, categoryIndex }) => {
  
  return (
  <>
    {
      courseInfo.map(({title, details}, courseIndex) => {
        let courseDetailsPresent = Object.keys(details).length > 0;
        return (
          <li>
              <p className="coursesClassTitle" key={`${title}ListItem`}>{title}</p>
                {
                  courseDetailsPresent ? 
                  <CourseDetails 
                    agency={agency}
                    categoryIndex={categoryIndex}
                    courseIndex={courseIndex}
                    details={details}
                    />
                  :
                  null
                }
          </li>
        )
      })
    }
  </> 
  )
}

export default CourseInfo;