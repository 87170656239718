import React from 'react';

import SectionImg from './SectionImg';
import Slider from './Slider';

import {pics, agencyInfo} from '../data/aboutData';


const About = () => (
  <div>
    <SectionImg title="About us" pageName="About" imgClass="defaultSectionImg" />
    <div className="aboutContentWrap pageLayout">
      <div className="aboutMissionContainer">
        <div className="aboutMissionTextWrap">
          <h3 >Our Mission</h3>
          <p className="aboutMissionText">
            In everything we do in life safety comes first, our company was developed for this reason. People always want security; here at Essential Safety Communication we can give you that! We stand by our services; we work hard to make sure you’re safe to the greatest degree possible. We will provide your Employees a knowledge to ensure all mechanical and physical aspects are covered for personal safety and health. We offer a full spectrum of services in keeping with the highest standards.
          </p>
        </div>
        <div className="aboutMissionImageWrap">
          <Slider pics={pics} />
        </div>
      </div>
        <h3 className="aboutCourseHeaderText">We are certified to teach classes by industry leading agencies</h3>
      <div className="aboutCourseContainer">
        <div className="aboutCourseWrap">
          {
          agencyInfo.map( ({name, description, logo, classesAvailable}) => (
            <div className="aboutCourse" key={`${name}-courses`}>
              <div className="aboutCourseAgencyLogo">
                <img className="aboutCourseformat" src={logo} alt={`${name} courses`}/>
              </div>
              <div className="aboutCourseAgencyText">
                <p className="aboutCourseformat">{description}</p>
                { classesAvailable ? 
                <a href={`courses/${name}/#${name}wrapper`}>{`View our ${name} courses`}</a> 
                :
                <a href={`courses/`}>{`View our courses`}</a>
                }
              </div> 
            </div>
          ))
          }
        </div>
      </div>
      <div className="aboutPoliciesLinkWrap">
        <a href="policies" className="aboutPoliciesLink">View our policies</a>
      </div>
    </div>
  </div>
)

export default About;