/*
  CalendarInterface methods

  addEvent(day:int, event:obj) -> instance
    adds an event to a days events array

  addHoliday(day:int, holidayName:string, message:string) -> instance
    adds a holiday and a message to a day

  clearDay(day:int) -> instance
    clears all events from a days events array

  createMonth(year:int, month:int) -> instance
    initiallizes monthData by creating an object for the
    month with an array of objects for each day of the month

  exportMonthData() -> object
    returns all data for the month in the form of an object

  getEventsForDay(day:int) -> array
    returns the events array for the requested day

  importMonthData(monthData:obj) -> instance
    takes in an an object and uses its data to set the 
    instances monthData

  isHoliday(day:int) -> bool
    returns true/false if the day passed is a holiday

  removeEvent(day:int, eventNo:int) -> instance
    removes the event at eventNo index from the 
    passed days events array

  removeHoliday(day:int) -> instance
    removes holday name and message from passed day

  toString() -> string
    return JSON.stringified version of the monthsData

  updateEvent(day:int, eventNo:int, event:object) -> instance
    updates the event at index eventNo in the passed 
    days events array

  events format
  {
    "courseName": "",
    "startTime": "",
    "endTime": ""
  }

*/

class CalendarInterface {
  constructor(year=null, month=null) {
    if(year !== null && month !== null) {
      this.createMonth(year, month);
    }
  }


  addEvent(dayNo, event) {
    this.monthData.days[dayNo].events.push(event);
    return this;
  }


  addHoliday(day, holidayName, message="") {
    this.monthData.days[day].holiday = holidayName;
    this.monthData.days[day].message = message;
    return this;
  }


  clearDay(day) {
    this.monthData.days[day].events = [];
    return this;
  }


  clearMonth() {
    this.createMonth(this.year, this.monthNo);
    return this;
  }

  
  createMonth(year, month){
    this.monthNo = month;
    this.year = year;
    
    let monthsOfYear = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    }

    this.monthName = monthsOfYear[month];

    let monthObject = new Date(year, month);
    let daysInMonth = new Date(year, month+1, 0).getDate();
    let newCalendarMonth = {
      monthName: this.monthName,
      monthNo: this.monthNo,
      year: this.year,
      days: []
    };

    for (let i = 1; i < daysInMonth + 1; i++) {
      monthObject.setDate(i)
      let day = {
        dayOfWeek: monthObject.getDay(),
        date: i,
        holiday: false,
        message: '',
        events: []
      }

      newCalendarMonth.days.push(day);
    }

    this.monthData = newCalendarMonth;
    return this;
  }


  exportMonthData() {
    // export deep copy of monthData
    let monthJSON = {
      monthName: this.monthName,
      monthNo: this.monthNo,
      year: this.year,
      days: []

    };
    this.monthData.days.forEach(day => {
      let eventsCopy = day.events.slice();
      let dayCopy = Object.assign({}, day);
      dayCopy.events = eventsCopy
      monthJSON.days.push(dayCopy);
    })
    console.log("exporting monthJSON", monthJSON);
    return monthJSON;
  }


  getEventsForDay(day) {
    return this.monthData.days[day].events;
  }

  copyDays(days) {
    const daysCopy = [];

    for (let i = 0, len = days.length; i < len; i++) {
      daysCopy[i] = {};
      Object.assign(daysCopy[i], days[i]);
      const events = days[i].events;
      const eventsCopy = [];
      if (events.length > 0) {
        for(let j = 0, len = events.length; j < len; j++) {
          eventsCopy[j] = {};
          Object.assign(eventsCopy[j], events[j]); 
        }
      }
      daysCopy[i].events = eventsCopy;
    }
    return daysCopy;
  }


  importMonthData(monthData) {
    try {
      if (typeof monthData !== 'object') {
        throw TypeError('monthData needs to be an object');
      }
      this.createMonth(monthData.year, monthData.monthNo)
      this.monthData.days = this.copyDays(monthData.days);
      console.log(this.days);
    } catch(error) {
      console.error('An error occured in CalendarInterface constructor: ', error.message);
    }

    return this;
  }


  isHoliday(day) {
    return Boolean(this.monthData.days[day].holiday)
  }


  removeEvent(day, eventNo) {
    this.monthData.days[day].events.splice(eventNo, 1);
    console.log("monthData after removing event", this.monthData.days);
    return this;
  }


  removeHoliday(day) {
    this.monthData.days[day].holiday = false;
    this.monthData.days[day].message = '';
    return this
  }

  
  toString() {
    return JSON.stringify(this.monthData);
  }


  updateEvent(day, eventNo, event) {
    this.monthData.days[day].events[eventNo] = event;
    return this;
  }

}

console.log(module);
// export for running from web app
export default CalendarInterface;

// var exports for running from node cli
// var exports = module.exports = {};
// const _CalendarInterface = CalendarInterface;
// exports.CalendarInterface = _CalendarInterface;
// this is not needed
// export { _CalendarInterface as CalendarInterface };


// const fs = require('fs');


// let calendarInstance = new CalendarInterface(2021, 7);
// const calendarJSON = calendarInstance.exportMonthData();
// console.log(typeof calendarJSON);

// fs.writeFile('./../data/calendarDataTEST.json', JSON.stringify(calendarJSON), err => {
//   if (err) {
//     console.log(err);
//     return
//   }
//   console.log("file written succesfully");
// });




