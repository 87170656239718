import React, { Component } from 'react';
import SectionImg from "./SectionImg";
import ContactForm from "./ContactForm";


class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      modalMessage: {line1: '', line2: ''},
      timeoutId: ''
    }

    this.submitHandler = this.submitHandler.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }


  setModalMessage(line1, line2) {
    this.setState({modalMessage: {line1, line2}});
  }


  toggleModal(timeout=4000) {
    let timeoutId = setTimeout(() => {
      this.setState({showModal: false});
    }, timeout);
    this.setState({showModal: true, timeoutId});
  }


  clear_form() {
    document.getElementById('inputName').value = "";
    document.getElementById('inputEmail').value = "";
    document.getElementById('inputSubject').value = "";
    document.getElementById('inputMessage').value = "";
  }


  createAndThrowError(line1, line2) {
    let error = new Error();
    error.line1 = line1;
    error.line2 = line2;
    throw error;
  }


  vaildate_form(name, email, subject, message) {
    const validator = require("email-validator");
    let errors = [];

    if (!name) errors.push("a name");
    if (!validator.validate(email)) errors.push("a valid email address");
    if (!subject) errors.push("a subject");
    if (!message) errors.push("a message");

    if (errors.length > 0) {
      let line1 = `Please enter: ${
        errors.length > 1 ? errors.join(', '): errors[0]
      }`;
      let line2 = "Check these fields and try again";
      this.createAndThrowError(line1, line2);
    }
  }


  sendEmail(name, email, subject, message) {
    let formData = {name, email, subject, message};
  
    return fetch('https://ikgv00tqnh.execute-api.us-east-1.amazonaws.com/beta2/sendmail', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
        if (!(data.statusCode && data.statusCode === 200)) {
          let line1 = "Sorry an error occured while sending your message";
          let line2 = "Please try again later or send us an email at kimsha@essentialsafety.nyc";
          return { line1, line2 };
        }
        return null;
    })
    .catch(error => {
      console.error(error);
      let line1 = "Sorry an error occured while sending your message";
      let line2 = "Please send us an email directly at kimsha@essentialsafety.nyc";
      return { line1, line2 };
    });
  }                            


  async submitHandler(event) {
    event.preventDefault();
    this.setState({error: null})
  
    let name = document.getElementById('inputName').value;
    let email = document.getElementById('inputEmail').value;
    let subject = document.getElementById('inputSubject').value;
    let message = document.getElementById('inputMessage').value;
  
    try {
      this.vaildate_form(name, email, subject, message);
      let emailError = await this.sendEmail(name, email, subject, message);
      if (emailError) {
        let { line1, line2 } = emailError;
        this.createAndThrowError(line1, line2);
      }
      let successLine1 = "Your message has been successfully sent!";
      let successLine2 = "We will get be in touch with you soon.";
      this.setModalMessage(successLine1, successLine2);
      this.toggleModal();
      this.clear_form();
    } catch(error) {
      let { line1, line2 } = error;
      this.setModalMessage(line1, line2);
      this.toggleModal(5000);
    }
  }


  hideModal() {
    clearTimeout(this.state.timeoutId);
    this.setState({showModal: false});
  }


    render() {
    return (
      <div>
        <SectionImg title="Contact us" pageName="Contact" imgClass="defaultSectionImg" />
        <div className="pageLayout contactOptionsContainer">
          <a className="contactOption" href="#messageWrap">
            <i className="contactIcon far fa-comments"></i>
            <h4 className="contactOptionHeader" >Send us a message.</h4>
            <p className="contactOptionText">Fill out the contact form bellow to send us an email to <b>kimsha@essentialsafety.nyc</b>. We usually respond within one business day.</p>
          </a>
          <a className="contactOption" href="tel:9174165465">
            <i className="contactIcon fas fa-phone-volume"></i>
            <h4 className="contactOptionHeader" >Talk to us.</h4>
            <p className="contactOptionText"><b>(917) 416-5465</b> Call our office and talk to someone at Essential Safety Communication. </p>
          </a>
          <a className="contactOption" href="https://goo.gl/maps/87JzkV9p8ohbNRte9" target="_blank" rel="noopener noreferrer">
            <i className="contactIcon fas fa-search-location"></i>
            <h4 className="contactOptionHeader" >See where our offices are located.</h4>
            <p className="contactOptionText">Our offices are at <b>256 East 138th Street Bronx, NY 10451</b>. Click here to check us out on Google maps and get directions.</p>
          </a>
        </div>
        <ContactForm submitHandler={this.submitHandler} hideModal={this.hideModal} show={this.state.showModal} message={this.state.modalMessage}/>
      </div>
    )
  }
}

export default Contact;