import React from 'react';
import { NavLink } from 'react-router-dom';

import linkInfo from '../data/navBarData';

const Footer = () => (
  <div className="footerContainer">
    <div className="footerTop">
      <div className="footerTopWrap">
        <div className="footerLinksContainer">
          <ul className="footerLinksWrap">
            {linkInfo.map(({id, name, link, index}) => (
              <li className="footerLink" key={`footer${name}${index}`}>
                <NavLink exact to={link}><p className="footerLinkText">{name}</p></NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="footerSocialContainer">
          <a href="https://www.facebook.com/EssentialSafetyCom/">
            <i className="footerSocialLink fab fa-facebook-square"></i>
          </a>
          <a href="https://www.linkedin.com/in/kimsha-diaz-6a13911b/">
            <i className="footerSocialLink fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
    <div className="footerMid">
      <div className="footerMidWrap">
        <div className="footerMidSquare">
          <img className="footerLogoImg" alt="OSHA logo" src={require("..\\Assets\\logos\\osha logo white.svg")}/>
        </div>
        <div className="footerMidSquare">
          <img className="footerLogoImg" alt="NYC Buildings logo" src={require("..\\Assets\\logos\\NYC_buildings logo white.svg")}/>
        </div>
        <div className="footerMidSquare">
          <img className="footerLogoImg" alt="IACET logo" src={require("..\\Assets\\logos\\IACET_logo white.svg")}/>
        </div>
        <div className="footerMidSquare">
          <img className="footerLogoImg" alt="ATSSA logo" src={require("..\\Assets\\logos\\ATSSA logo white.svg")}/>
        </div>
        <div className="footerMidSquare">
          <img className="footerLogoImg" alt="NCCER logo" src={require("..\\Assets\\logos\\NCCER.svg")}/>
        </div>
      </div>
    </div>
    <div className="footerLower">
      <p className="footerLowerText">©copyrights 2020. Essential Safety Communication, Inc.</p>
      <NavLink exact to="policies" className="footerPoliciesLink"><p >Policies</p></NavLink>
    </div>
  </div>
)

export default Footer;