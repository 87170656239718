import React from 'react';
import { withRouter } from 'react-router-dom';

const HomeHeader = ({ title, imageSrc, history }) => {
    return (
      <div id="homeHeader" className="homeHeaderFrame">
        <img className="homeHeaderFrameInnerImage" src={imageSrc} alt=""/>
        <p className="homeHeaderPageTitle" >{title}</p>
        <div className="homeHeaderCallToActionButtonWrap pageLayout">
          <button 
          onClick={() => {history.push('Courses')}}
          className="homeHeaderCallToActionButton">
            View Courses
          </button>
          <button 
          onClick={() => {history.push('Contact')}}
          className="homeHeaderCallToActionButton">
            Contact Us
          </button>
        </div>
      </div>
    )
  }

export default withRouter(HomeHeader);