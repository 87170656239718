import React from 'react';

const Holiday = ({holiday, message}) => (
  <>
    {
      holiday ?
      <>
        <p className="calendarText">{holiday}</p>
        <p className="calendarText">{message}</p>
      </>
      :
      null
    }
  </>
);

export default Holiday;