import React from 'react';
import SectionImg from './SectionImg';

import { policies } from "../data/policiesData"

const Policies = () => (
  <div>
    <SectionImg title="Our Policies" pageName="Policies" imgClass="defaultSectionImg" />
    <div className="policiesContainer">
        <h3>Company Policies</h3>
        {
          policies.map(({policyName, description}) => (
            <div key={`${policyName}Policy`} className="policyWrapper">
              <h4>{policyName}</h4>
              <p>{description}</p>
            </div>
          ))
        }
      </div>
  </div>
)

export default Policies;