import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import SectionImg from "../SectionImg";
import Month from "./Month";
import Admin from "./Admin";

import StorageInterface from "../../api scripts/storageInterface";
import CalendarInterface from "../../api scripts/calendarInterface";


class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewYear: new Date().getFullYear(),
      viewMonth: new Date().getMonth(),
      allCalendarData: {}
    }
  }

  async componentDidMount() {
    this.setCurrentDateToState();
    let calendarData = await this.getCalendarData();
    if (calendarData) {
      this.setCalendarDataToState(calendarData);
    }
    console.log("the state is: ", this.state);
  }

  async getCalendarData() {
    let dl = await StorageInterface.downloadData('prod');
    if (dl.data !== undefined){
      return dl.data;
    } else {
      return this.makeCalendarData();
    }
  }

  makeCalendarData() {
    const viewYear = new Date().getFullYear();
    const viewMonth = new Date().getMonth();
    console.log("making the calendarData for: ", viewYear, viewMonth);
    return [
      new CalendarInterface(viewYear, viewMonth)
        .exportMonthData()
    ];
  }

  setCalendarDataToState(calendarData) {

    this.setState((state, props) => {
      const stateCalendarData = state.allCalendarData;

      calendarData.forEach(month => {
        const {year, monthNo} = month;

        if (!stateCalendarData[year]) stateCalendarData[year] = {};
        stateCalendarData[year][monthNo] = month;
      });

      return {allCalendarData: stateCalendarData};
    });

  }


  setCurrentDateToState() {
    this.setState({
      viewYear: new Date().getFullYear(),
      viewMonth: new Date().getMonth()
    });
  }


  fillExtraSpacesOnCalendar(monthData) {
    let buffer = { "dayOfWeek": null,
    "date": null,
    "holiday": false,
    "message": null,
    "events": [] };
    
    let bufferCount = monthData.days[0].dayOfWeek;
    let bufferedMonth = monthData.days.slice();

    for (let i = 0; i < bufferCount; i++){
      bufferedMonth.unshift(buffer);
    }

    while (bufferedMonth.length % 7 !== 0) {
      bufferedMonth.push(buffer);
    }

    return bufferedMonth;
  }


  breakMonthIntoWeeks(bufferedMonth) {
    let monthInWeeks = [];

    for (let i = 0; i < bufferedMonth.length; i += 7) {
      monthInWeeks.push(bufferedMonth.slice(i, i + 7))
    }

    return monthInWeeks;
  }


  getMonthInWeeks(monthData) {
    const bufferedMonth = this.fillExtraSpacesOnCalendar(monthData);
    const monthInWeeks = this.breakMonthIntoWeeks(bufferedMonth);
    return monthInWeeks;
  }

  getMonthData() {
    const { allCalendarData, viewMonth, viewYear } = this.state;
    let monthData;
    if (allCalendarData[viewYear] !== undefined && 
      allCalendarData[viewYear][viewMonth] !== undefined) {
      monthData = allCalendarData[viewYear][viewMonth];
    }
    return monthData;
  }

  setMonthData(monthData) {
    const { allCalendarData, viewMonth, viewYear } = this.state;
    if (allCalendarData[viewYear] !== undefined && 
      allCalendarData[viewYear][viewMonth] !== undefined) {
        // TODO set allCalendarData back to the state
      allCalendarData[viewYear][viewMonth] = monthData;
    }
    this.setCalendarDataToState(allCalendarData);
  }

  addClassHandler = (date) => {
    console.log(`Add a class to ${JSON.stringify(this.getMonthData().days[date -1])}`);
  }

  removeClassHandler = (eventNo, date) => {
    const monthData = this.getMonthData();
    const calendarInterface = new CalendarInterface();
    const month = calendarInterface.importMonthData(monthData);
    month.removeEvent(date -1, eventNo);
    this.setMonthData(month.exportMonthData());
    // TODO - Update the UI when an event is removed 
    console.log(">>>>>>>>> events "+ JSON.stringify(month.getEventsForDay(date -1)));
    console.log(`Remove class ${eventNo} from ${monthData.monthName} the ${date}`);
  }

  updateClassHandler = (eventNo, date) => {
    const monthData = this.getMonthData();
    console.log(`Update class ${eventNo} from ${monthData.monthName} the ${date}`);
  }

  adminFuncs = {
    addClass: this.addClassHandler,
    removeClass: this.removeClassHandler,
    updateClass: this.updateClassHandler
  }


  render() {
    let { history, match } = this.props;
    const { allCalendarData, viewMonth, viewYear } = this.state;
    const isAdmin = match.path === "/calendar/admin";
    console.log('in calendar', allCalendarData);

    let monthInWeeks;
    let monthData = this.getMonthData();
    if (monthData){
      monthInWeeks = this.getMonthInWeeks(monthData)
    }
  
    return (
      <div id="calendarContainer">
        <SectionImg title="Class calendar" pageName="Calendar" imgClass="defaultSectionImg" />
        {/* Create popup menu to update classes from clicks on single day
         < Route 
          exact path="/calendar/admin" 
          render={
            () => <Admin 
                    allCalendarData={this.state.allCalendarData}
                    viewMonth={this.state.viewMonth} 
                    viewYear={this.state.viewYear} 
                    monthData={monthData} />
          } /> */}
        {
          monthInWeeks !== undefined ?
            <Month 
              adminFuncs={this.adminFuncs}
              monthInWeeks={monthInWeeks}
              monthData={monthData}
              history={history}
              match={match}
              />
            :
            <div><p>Loading...</p></div>
        }
      </div>
    )
  }
}

export default Calendar;